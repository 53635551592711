const ViewAllIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '55', height = '55', fill = '#FF4F44' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_bd_1866_12094)">
      <rect
        x="9.59521"
        y="7.5"
        width="35.81"
        height="35.81"
        rx="17.905"
        fill="white"
        fillOpacity="0.02"
        shapeRendering="crispEdges"
      />
      <path
        d="M32.4518 29.5324C33.3623 29.5324 34.1027 28.792 34.1027 27.8816V19.6274C34.1027 18.717 33.3623 17.9766 32.4518 17.9766H22.5468C21.6364 17.9766 20.896 18.717 20.896 19.6274V27.8816C20.896 28.792 21.6364 29.5324 22.5468 29.5324H32.4518ZM22.5468 19.6274H32.4518L32.4535 27.8816H22.5468V19.6274ZM20.896 31.1832H34.1027V32.8341H20.896V31.1832Z"
        fill={fill}
      />
      <rect
        x="10.0952"
        y="8"
        width="34.81"
        height="34.81"
        rx="17.405"
        stroke="url(#paint0_linear_1866_12094)"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_bd_1866_12094"
        x="-8.40479"
        y="-10.5"
        width="71.8101"
        height="71.8101"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="9" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1866_12094" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_1866_12094"
          result="effect2_dropShadow_1866_12094"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1866_12094"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1866_12094"
        x1="12.8379"
        y1="3.47137"
        x2="52.0364"
        y2="7.99986"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.05" />
        <stop offset="0.732287" stopColor="white" stopOpacity="0.05" />
        <stop offset="1" stopColor="white" stopOpacity="0.15" />
      </linearGradient>
    </defs>
  </svg>
)

export default ViewAllIcon
