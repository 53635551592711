import { customStyled, FlexBox } from '@rario/shared-components'

export const Line = customStyled(FlexBox)<{ width?: string; height?: string }>`
    width: ${({ width = '328px' }) => width};
    height: ${({ height = '20px' }) => height};
    background: #0E131A;
    border-radius: 8px;
`

export const Card = customStyled(FlexBox)<{ height?: string; width?: string }>`
    min-width: ${({ width = '328px' }) => width};
    height: ${({ height = '140px' }) => height};
    background: #0E131A;
    border-radius: 8px;
    margin-right: 15px;
`

export const ShimmerElement = customStyled.div`
    position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;

        background: linear-gradient(99.54deg, rgba(20, 26, 33, 0) 16.98%, #20252C 42.33%, #20252C 47%, rgba(22, 28, 35, 0) 70.11%);

        animation: shimmer 1s infinite linear;

        @keyframes shimmer {
            0% {
                transform: translateX(-20%);
            }
            100% {
                transform: translateX(120%);
            }
        }
`
