import { customStyled, FlexBox } from '@rario/shared-components'

export const FirstFoldWrapper = customStyled(FlexBox)`
width:100vw;
justify-content:space-around;
flex-wrap:wrap;
align-items:center;
position:relative;
overflow:hidden;
`
