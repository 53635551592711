const RightArrowSmallIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '14', height = '13', fill = 'white' }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.16406 6.71387H12.8307M12.8307 6.71387L7.83073 1.71387M12.8307 6.71387L7.83073 11.7139"
      stroke={fill}
      strokeWidth="1.71704"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default RightArrowSmallIcon
