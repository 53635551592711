import { customStyled, FlexBox, TextField, theme } from '@rario/shared-components'

export const CardWrapper = customStyled(FlexBox)`
    backdrop-filter: blur(25px);
    background: ${theme.colors.whites[2]};
    &:hover {
            background: ${theme.colors.whites[3]};
    }
    &:hover .cardContainer{
            filter:brightness(1.2)!important;
    } 
`
export const CarouselWrapper = customStyled(FlexBox)`
        display: flex;
        width: 100%;
        flex-direction: column;
        position: relative;
        @media(max-width: ${theme.breakpoints[1]}) {
                & .carousel-custom-btn {
                        display: none;
                }
                padding-top: 0px;
        }
        
`

export const TextWrapper = customStyled(TextField)`
overflow: hidden;
max-height:42px;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
`
