import { FlexBox, Image } from '@rario/shared-components'
import useCloudinaryUrl from 'hooks/useCloudinaryUrl'
import { NFTDetailSingleImageProps } from 'interfaces/nftDetail'

const NFTDetailSingleImage: React.FunctionComponent<NFTDetailSingleImageProps> = ({
  imageUrl,
  height = '456px',
  width = '285px',
  margin = '0px',
  onClick,
  cursor,
  alt = '',
  isFromPackReveal = false,
}) => {
  const imageSource = useCloudinaryUrl(imageUrl, '171', '274', 'auto:good', false, false, 'f_webp')
  return (
    <FlexBox className="card-img">
      <Image
        height={height}
        width={width}
        margin={margin}
        src={isFromPackReveal ? imageUrl : imageSource}
        onClick={() => onClick && onClick()}
        cursor={cursor}
        name={alt}
      />
    </FlexBox>
  )
}

export default NFTDetailSingleImage
