import { doGet } from 'api'
import API_CONSTANTS from './constants'

export const getTypesenseObject = (index: string, id: string) => {
  return doGet(
    API_CONSTANTS.GET_TYPESENSE_OBJECT,
    { id, isAbsUrl: true, typesenseHost: process.env.TYPESENSE_HOST, collection: index },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-TYPESENSE-API-KEY': process.env.TYPESENSE_API_KEY,
      },
    }
  )
}
