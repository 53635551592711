import { Carousel, FlexBox, TextField, theme } from '@rario/shared-components'
import { NFTCatalogDoc } from 'interfaces'
import { Hit } from 'react-instantsearch-core'
import { useContext, useEffect, useState } from 'react'
import { CarouselWrapper } from 'components/Picks/Picks.styles'
import NFTCard from 'components/NFTCard/NFTCard'
import HeadingContent from 'components/HeadingContent/HeadingContent'
import { AppConfigContext } from 'contexts/AppConfigContext'
import { WidgetIdsEnum } from 'interfaces/homePage'
import { getMarketplaceWidgetData } from 'api/Algolia.api'
import ViewAllCard from './ViewAllCard'
import { IApiException } from 'api/Api.exception'
import { trackApiErrorResponse } from 'utils/trackEventUtils'
import WidgetsShimmer from './WidgetsShimmer'
import { parseLeagueFilters } from 'utils/searchUtil'
import { getPlatformName } from 'utils/platform'

const MarketplaceWidget: React.FunctionComponent = () => {
  const [marketplaceData, setMarketplaceData] = useState<Hit<NFTCatalogDoc>[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const appConfig = useContext(AppConfigContext)
  const { leagueSortingCard } = appConfig

  const getMarketplaceListings = async (): Promise<Hit<NFTCatalogDoc>[]> => {
    try {
      setIsLoading(true)
      const league = parseLeagueFilters(leagueSortingCard)
      const marketplaceData = await getMarketplaceWidgetData(league)
      setIsLoading(false)
      return marketplaceData as Hit<NFTCatalogDoc>[]
    } catch (e) {
      const error = e as IApiException
      trackApiErrorResponse(error, {
        subCategory: 'marketplace-listing_api_error_reponse',
        category: 'marketplace',
      })
      // const errorResponse = error.response ? error.response : { message: error.message }
      // emitCustomEvent('errorCodes', errorResponse)
      // silent catch
      setIsLoading(false)
      return []
    }
  }

  useEffect(() => {
    getMarketplaceListings().then((res) => {
      setMarketplaceData(res)
    })
  }, [])

  if (isLoading) {
    return (
      <FlexBox flexDirection={'column'}>
        {[1, 2].map((index) => (
          <WidgetsShimmer key={`shimmer-marketplace${index}`} height="198px" />
        ))}
      </FlexBox>
    )
  }
  return (
    <>
      {!!marketplaceData?.length && (
        <FlexBox mt={'40px'} flexDirection={'column'} width={'100%'} overflow={'hidden'}>
          <FlexBox mx={'10px'}>
            <FlexBox ml={'0px'} flexDirection={'column'} width={'100%'}>
              <FlexBox
                width={'98.5%'}
                flexDirection={'column'}
                position={'relative'}
                zIndex={9}
                mb={'24px'}
              >
                <FlexBox zIndex={999}>
                  <HeadingContent
                    heading={'Fan Marketplace'}
                    subHeading={''}
                    markerLeftPosition={'-10px'}
                    widgetParentId={WidgetIdsEnum.MARKETPLACE_WIDGET}
                    category="homepage_marketplace_section"
                  >
                    <FlexBox flexDirection={'column'}>
                      <TextField
                        fontSize={'12px'}
                        lineHeight={'16px'}
                        fontWeight={theme.fontWeights[4]}
                        fontFamily={theme.fonts.secondary}
                        pb={10}
                        content="Fan Marketplace?"
                      />
                      <TextField
                        fontSize={'12px'}
                        lineHeight={'16px'}
                        fontWeight={theme.fontWeights[4]}
                        fontFamily={theme.fonts.secondary}
                        color={theme.colors.lightGrey}
                        pb={10}
                        content={`Buy/sell individual Player Cards amongst other fans in the ${getPlatformName()} ecosystem.`}
                      />
                      <TextField
                        fontSize={'12px'}
                        lineHeight={'16px'}
                        fontWeight={theme.fontWeights[4]}
                        fontFamily={theme.fonts.secondary}
                        color={theme.colors.lightGrey}
                        pb={0}
                        content="This is how you can fine-tune your collection and create the perfect team in our cricket strategy game."
                      />
                    </FlexBox>
                  </HeadingContent>
                </FlexBox>
              </FlexBox>
              <CarouselWrapper position="relative" display={'block'} flexDirection={'row'}>
                <Carousel
                  slidesToScroll={1}
                  slidesToScrollMobile={1}
                  showDots={false}
                  arrows={false}
                  showCustomDots
                >
                  {!!marketplaceData?.length &&
                    marketplaceData?.map((item, index) => {
                      return (
                        <FlexBox key={`${item.objectID}-${index}`} mx={'7px'}>
                          <NFTCard hit={item} appendRefererInLink />
                        </FlexBox>
                      )
                    })}
                  {!!marketplaceData?.length && <ViewAllCard cardType="card" />}
                </Carousel>
              </CarouselWrapper>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
    </>
  )
}

export default MarketplaceWidget
