import { Carousel, FlexBox } from '@rario/shared-components'
import { NFTTokenDoc } from 'interfaces'
import { Hit } from 'react-instantsearch-core'
import { useContext, useEffect, useState } from 'react'
import PicksWidgetHeading from '../Picks/PicksWidgetHeading'
import { CarouselWrapper } from 'components/Picks/Picks.styles'
import PicksNFTCard from 'components/Picks/PicksNFTCard'
import { getPicksWidgetData } from 'api/Algolia.api'
import ViewAllCard from './ViewAllCard'
import { AppConfigContext } from 'contexts/AppConfigContext'

const PicksWidget: React.FunctionComponent = () => {
  const [picksData, setPicksData] = useState<Hit<NFTTokenDoc>[]>([])

  const { isPickEnabled } = useContext(AppConfigContext)

  const getPicks = async (): Promise<Hit<NFTTokenDoc>[]> => {
    try {
      const picksData = await getPicksWidgetData()
      return picksData as Hit<NFTTokenDoc>[]
    } catch (e) {
      // const error = e as IApiException
      // const errorResponse = error.response ? error.response : { message: error.message }
      // emitCustomEvent('errorCodes', errorResponse)
      // silent catch
      return []
    }
  }

  useEffect(() => {
    getPicks().then((res) => {
      setPicksData(res)
    })
  }, [])

  if (picksData.length === 0) {
    return null
  }

  return isPickEnabled ? (
    <FlexBox flexDirection={'column'} width={'100%'} overflow={'hidden'} mt={'40px'}>
      <FlexBox mx={'10px'}>
        <FlexBox ml={'0px'} flexDirection={'column'} width={'100%'}>
          <PicksWidgetHeading
            startTime={picksData[0]?.campaign_start_ts}
            endTime={picksData[0]?.campaign_end_ts}
          />

          <CarouselWrapper position="relative" display={'block'} flexDirection={'row'}>
            <Carousel
              slidesToScroll={1}
              slidesToScrollMobile={1}
              showDots={false}
              arrows={false}
              showCustomDots
            >
              {picksData.map((item, index) => {
                return (
                  <FlexBox key={`picks-${index}`} mx={'7px'}>
                    <PicksNFTCard hit={item} appendRefererInLink />
                  </FlexBox>
                )
              })}
              {!!picksData?.length && <ViewAllCard cardType="picks" />}
            </Carousel>
          </CarouselWrapper>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  ) : (
    <></>
  )
}

export default PicksWidget
