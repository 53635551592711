import { customStyled, theme, FlexBox, TextField } from '@rario/shared-components'

export const WidgetLayoutContainer = customStyled(FlexBox)`
  cursor:pointer;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 14px 14px -5px rgb(0 0 0 / 15%);
  backdrop-filter: blur(5px);
  border-radius: 4px;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  padding-right:12px;
  padding-left:12px;
  padding-top:6px;
  padding-bottom:6px;
`

export const InfoWrapper = customStyled(FlexBox)`
margin-left:15px;
margin-top:10px;
flex-direction:column;
  width:100%;
  @media(max-width:768px){
    width:90%;
  }
`

export const SoldOutText = customStyled.span`
// font-family: 'Rubik';
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 13px;
text-transform: uppercase;
margin-left:6px;
width: 53px;
height: 13px;
`

export const CarouselWrapper = customStyled(FlexBox)`
display: flex;
flex-direction: column;
position: relative;
padding-top: 65px;
   @media(max-width: ${theme.breakpoints[2]}) {
       padding-top: 0px;
       
`
export const GutterSpaceRow = customStyled(FlexBox)`
    max-width:calc(1256px);
    margin: 0px auto;
    @media(max-width: ${theme.breakpoints[1]}) {
     margin:0px;
    }
`
export const PlayerNameText = customStyled(TextField)`
white-space: nowrap;

overflow: hidden;
text-overflow: ellipsis;
`
export const TextWrap = customStyled(FlexBox)`
flex-wrap: nowrap;
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
 width: 200px;
`

export const LatestSaleWrapper = customStyled(FlexBox)`
-ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
`
