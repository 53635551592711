import { customStyled, ThemeType, FlexBox, TextField } from '@rario/shared-components'

export const CarousalWrapper = customStyled(FlexBox)<{ theme?: ThemeType }>`
    flex-direction: column;
    position: relative;
    width: 100%;
    // display:block;
    @media(max-width:${({ theme }) => theme.breakpoints[0]}){
        width: 100vw;
    }
`

export const CarousalInner = customStyled(FlexBox)`
        padding-top: 50px;
`
export const InfoHeading = customStyled(TextField)`
    @media(max-width: 360px) {
        font-size: 20px;
        line-height: 22px;
    }
`
