import { FlexBox, TextField, theme, trackEvent } from '@rario/shared-components'
import ViewAllIcon from 'assets/svg/ViewAllIcon'
import { ViewAllCardWrapper } from 'components/Picks/Picks.styles'
import { VaultNFTBackgroundContainer } from 'components/VaultNFTCard/VaultNFTCard.styles'
import { CatalogTypeEnum } from 'interfaces'
import Link from 'next/link'
import React, { FunctionComponent } from 'react'

const ViewAllCard: FunctionComponent<{ cardType: string }> = ({ cardType }) => {
  return (
    <FlexBox>
      <Link href={cardType === CatalogTypeEnum.PICKS ? '/picks' : '/marketplace'}>
        <FlexBox
          mx={'7px'}
          pt={'25px'}
          onClick={() => {
            trackEvent('Section Interacted', 'click', {
              sub_category:
                cardType === CatalogTypeEnum.PICKS
                  ? 'homepage-single_cards_widget'
                  : 'homepage-marketplace_widget',
              category: 'homepage',
              view_type: 'constrainted',
              click_type: 'button',
              click_action:
                cardType === CatalogTypeEnum.PICKS
                  ? 'click_explore_single_cards_cta_home'
                  : 'click_explore_marketplace_cta_home',
              click_text:
                cardType === CatalogTypeEnum.PICKS
                  ? 'View All Single Cards'
                  : 'View All in Marketplace',
            })
          }}
        >
          <ViewAllCardWrapper
            width={'160px'}
            height={cardType === CatalogTypeEnum.PICKS ? '293px' : '305px'}
          >
            <VaultNFTBackgroundContainer
              width={'100%'}
              mt={'80px'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <ViewAllIcon width="90px" height="90px" fill={theme.colors.secondary} />
              <TextField
                textAlign={'center'}
                fontFamily={theme.fonts.primary}
                letterSpacing={theme.letterSpacings.primary}
                textTransform={'capitalize'}
                fontSize={'14px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                content={`View All ${
                  cardType === CatalogTypeEnum.PICKS ? 'Single Cards' : 'in Marketplace'
                }`}
                px={'30px'}
              />
            </VaultNFTBackgroundContainer>
          </ViewAllCardWrapper>
        </FlexBox>
      </Link>
    </FlexBox>
  )
}

export default ViewAllCard
