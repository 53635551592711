import { FlexBox, customStyled, ThemeType } from '@rario/shared-components'
import { PaddingProps, padding } from 'styled-system'

export const NFTCardWrapper = customStyled.div<
  {
    theme?: ThemeType
    isPick?: boolean
    isHomeLandingPage?: boolean
  } & PaddingProps
>`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  ${padding};
  background: linear-gradient(225deg, #444 ${({ isPick = true }) =>
    isPick ? '19px' : '12px'}, #101318 ${({ isPick = true }) => (isPick ? '19px' : '15px')});
  box-shadow: inset 20.1667px -20.1667px 20.1667px rgba(13, 17, 21, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 5px;
  border: 0.5px solid #444;
  margin-bottom: ${({ isPick = true }) => (isPick ? '0px' : '30px')};
  height:100%;
  width: ${({ isHomeLandingPage }) => (isHomeLandingPage ? '175px' : '170px')};
  mask-image: linear-gradient(225deg, transparent ${({ isPick = true }) =>
    isPick ? '19px' : '14px'}, white ${({ isPick = true }) => (isPick ? '19px' : '14px')});
  & .card-img img{
    @media (max-width: 320px) {
      width: 140px;
      height: 214px;
    }
  }
`

export const NFTTitleContainer = customStyled(FlexBox)<{ pt?: any; width?: string }>`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ width = '150px' }) => width};
  height:auto;
  display:block;
  padding-top: ${({ pt = 0 }) => pt};
  color: rgb(255, 255, 255);
  z-index: 10;
  display:block;
  padding-top: ${({ pt = 0 }) => pt};
  @media (max-width: 320px) {
    width: 140px;
  }
`

export const NFTCardBottom = customStyled(FlexBox)<{
  theme?: ThemeType
  isPick?: boolean
  disabled?: boolean
}>`
  background: 'linear-gradient(142.2deg, rgba(23, 26, 30, 0.8) 0%, rgba(14, 17, 20, 0.8) 100%)';
  box-shadow: 0px -1px 0px rgba(255, 255, 255, 0.1);
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')}
  
`

export const VaultNFTBackgroundContainer = customStyled(FlexBox)<{ theme?: ThemeType }>`
    mask-image: linear-gradient(225deg, transparent 15px, white 15px);
`

export const XPContainer = customStyled(FlexBox)<{ theme?: ThemeType }>`
  height: 25px;
  width: 65px;
  justify-content: center;
  align-item: center;
  background: linear-gradient(180deg, #686B70 -13.71%, rgba(51, 51, 51, 0.65) 91.08%);
  box-shadow: 5.49593px 0px 9.15989px rgba(0, 0, 0, 0.45);
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  clip-path: polygon(0 0, 100% 0, 82% 62%, 18% 62%);
  top: 158px;
`

export const VaultNFTIconWrapper = customStyled.span<{ isLastIcon?: boolean }>`
  position: relative;
  margin-bottom: ${({ isLastIcon = false }) => (isLastIcon ? '18px' : '-6px')};
  height: ${({ isLastIcon = false }) => (isLastIcon ? '0' : '36px')};
`

export const NftParentWrapper = customStyled(FlexBox)`
  flex-direction:column;
  @media(max-width: 365px) {
    transform: scale(0.93);
  }
`
