import { FlexBox, TextField, theme } from '@rario/shared-components'
import { HeadingProps } from 'interfaces'
import HeadingContent from './HeadingContent'

const Heading: React.FunctionComponent<HeadingProps> = ({
  heading,
  subHeading,
  infoDesc,
  infoHeading = heading,
  margin,
  widgetParentId = '',
  pageSource = '',
}) => {
  return (
    <FlexBox width={'96%'} position={'relative'} mb={margin} zIndex={99} px={'10px'}>
      <HeadingContent
        heading={heading}
        subHeading={subHeading}
        markerLeftPosition={'-10px'}
        category={pageSource}
        widgetParentId={widgetParentId}
      >
        <FlexBox flexDirection={'column'}>
          <TextField
            fontSize={'12px'}
            lineHeight={'16px'}
            fontWeight={theme.fontWeights[4]}
            fontFamily={theme.fonts.secondary}
            pb={10}
            content={infoHeading}
          />
          <TextField
            fontSize={'12px'}
            lineHeight={'16px'}
            fontWeight={theme.fontWeights[4]}
            fontFamily={theme.fonts.secondary}
            color={theme.colors.lightGrey}
            pb={10}
            html={infoDesc}
          />
        </FlexBox>
      </HeadingContent>
    </FlexBox>
  )
}

export default Heading
