import { useState, useEffect, useMemo } from 'react'
import {
  Carousel,
  FlexBox,
  Loader,
  TextField,
  theme,
  trackEvent,
  useInView,
} from '@rario/shared-components'
import { PacksDoc, UserCohorts } from 'interfaces'
import LeagueFilters from 'components/LeagueFilters'

import Link from 'next/link'
import { CarouselWrapper } from 'components/PreviousDrops/PreviousDropCard.styles'
import PackBox from 'components/PackBox'
import PackListEmptyScreen from 'components/PackList/PackListEmptyScreen'
import Heading from 'components/HeadingContent/Heading'
import { WidgetIdsEnum } from 'interfaces/homePage'
import { getPacksWidgetData } from 'api/Algolia.api'
import ViewAllIcon from 'assets/svg/ViewAllIcon'
import { ViewAllPackWrapper } from 'components/Picks/Picks.styles'
import { useCustomUser } from 'contexts/CustomUserProvider'

const PacksWidget: React.FunctionComponent = () => {
  const { user } = useCustomUser()
  const [packs, setPacks] = useState<PacksDoc[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentFacetValue, setCurrentFacetValue] = useState<string | null>(null)

  const [carouselReInit, setCarouselReInit] = useState(false)
  const [element, setElement] = useState<HTMLElement | null | undefined>()
  const [inView] = useInView(element)

  const getPacksByLeague = async (): Promise<PacksDoc[]> => {
    try {
      const packs = await getPacksWidgetData(
        currentFacetValue,
        user?.metadata?.cohorts?.[0] as UserCohorts
      )
      setIsLoading(false)
      return packs as PacksDoc[]
    } catch (err) {
      return []
    }
  }

  useEffect(() => {
    if (currentFacetValue) {
      getPacksByLeague().then((res) => {
        setPacks(res)
      })
    } else {
      setIsLoading(false)
    }
  }, [currentFacetValue])

  useEffect(() => {
    setCarouselReInit(!carouselReInit)
  }, [packs])

  const facetValueChangeCallback = (item: string) => {
    setIsLoading(true)
    setCurrentFacetValue(item)
  }

  /* track event on scroll */
  useEffect(() => {
    if (inView) {
      trackEvent('Section Viewed', 'track', {
        sub_category: 'homepage-packs_widget',
        category: 'homepage',
        view_type: 'constraints',
        filter_values: currentFacetValue,
        filter_type: 'leagues',
        total_packs: packs?.length,
      })
    }
  }, [inView])

  const RenderLatestPacks = useMemo(() => {
    return (
      packs?.length && (
        <FlexBox flexDirection={'column'} ref={setElement}>
          <FlexBox flexDirection={'column'} mt={'24px'} width="100%">
            <FlexBox
              flexWrap={'wrap'}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              width={'100%'}
            >
              <CarouselWrapper position="relative" display={'block'}>
                <Carousel
                  slidesToScroll={1}
                  slidesToScrollMobile={1}
                  showCustomDots
                  sliderHighlightColor={theme.colors.white}
                  arrows={false}
                  reInitCarousel={carouselReInit}
                  totalSlides={packs?.length}
                >
                  {packs?.map((item, index) => (
                    <FlexBox mx={'10px'} flexDirection={'column'} key={index}>
                      <PackBox
                        packsDoc={{ ...item }}
                        key={`${item.objectID}-${index}`}
                        appendRefererInLink
                        currentFacetValue={currentFacetValue}
                      />
                    </FlexBox>
                  ))}
                  {packs?.length > 2 && (
                    <FlexBox>
                      <Link href={`/packs${currentFacetValue && `?league=${currentFacetValue}`}`}>
                        <FlexBox
                          mx={'10px'}
                          onClick={() => {
                            trackEvent('Section Interacted', 'click', {
                              sub_category: 'homepage-packs_widget',
                              category: 'homepage',
                              view_type: 'constrainted',
                              click_type: 'button',
                              click_action: 'click_explore_market_cta_home',
                              click_text: 'View All Packs',
                            })
                          }}
                        >
                          <ViewAllPackWrapper width={'160px'} height={'190px'}>
                            <FlexBox
                              width={'100%'}
                              mt={'30px'}
                              flexDirection={'column'}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              <ViewAllIcon
                                width="90px"
                                height="90px"
                                fill={theme.colors.secondary}
                              />
                              <TextField
                                textAlign={'center'}
                                fontFamily={theme.fonts.primary}
                                textTransform={'capitalize'}
                                fontSize={'14px'}
                                fontWeight={'600'}
                                lineHeight={'20px'}
                                content={`View All Packs`}
                                px={'40px'}
                              />
                            </FlexBox>
                          </ViewAllPackWrapper>
                        </FlexBox>
                      </Link>
                    </FlexBox>
                  )}
                </Carousel>
              </CarouselWrapper>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )
    )
  }, [packs])
  return (
    <FlexBox mt={currentFacetValue ? '40px' : '0px'} flexDirection={'column'}>
      {currentFacetValue && (
        <Heading
          heading="Latest Packs of Player Cards"
          infoHeading="Packs of Cards?"
          infoDesc="Build your collection with packs of Player Cards and own a roster of players to choose from for your teams! 
                <br/>
                Packs drop (are made available) every now and again, so it's a good idea to grab them while you can."
          margin="15px"
          widgetParentId={WidgetIdsEnum.PACKS_WIDGET}
        />
      )}
      <LeagueFilters facetValueChangeCallback={facetValueChangeCallback} />
      {currentFacetValue && (
        <FlexBox>
          <FlexBox flex={1} flexDirection="column" width="100%">
            <FlexBox width={'100%'} flexDirection="column">
              {isLoading ? (
                <Loader height="50vh" />
              ) : packs?.length > 0 ? (
                RenderLatestPacks
              ) : (
                <PackListEmptyScreen />
              )}
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
    </FlexBox>
  )
}

export default PacksWidget
