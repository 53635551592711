import React from 'react'
import { FlexBox, Image, TextField, trackEvent, theme } from '@rario/shared-components'
import { useRouter } from 'next/router'
import { LatestSaleWidgetProps } from 'interfaces'
import { getActivityTimeFormat, getCDNUrl, truncateString } from 'utils/utils'
import { WidgetLayoutContainer } from './LatestSaleStyle'
import useCurrencyConversion from 'hooks/useCurrencyConversion'
import { SalesWidgetPlaceholder } from 'constant'
import useCloudinaryUrl from 'hooks/useCloudinaryUrl'

const LatestCardSaleItems: React.FunctionComponent<{
  responseVal: LatestSaleWidgetProps
}> = ({ responseVal }) => {
  const { image, playerName, saleDate, price, scarcity } = responseVal
  const router = useRouter()

  const [formattedPrice] = useCurrencyConversion(price, { localize: true })

  const imageSource = useCloudinaryUrl(image, '120', '185', 'auto:good', false, false, 'f_webp')
  return (
    <WidgetLayoutContainer
      mr={'10px'}
      minWidth={'fit-content'}
      onClick={() => {
        trackEvent('Section Interacted', 'click', {
          category: 'homepage',
          sub_category: 'homepage-latest_sales_cards',
          view_type: 'constrained',
          type: 'cards',
          player_name: playerName,
          scarcity,
          sale_date: saleDate,
          price,
        })
        router.push(`/marketplace?query=${playerName}`)
      }}
    >
      {image && (
        <FlexBox
          justifyContent="center"
          height={'74px'}
          width={'48px'}
          mr="12px"
          position={'relative'}
        >
          <FlexBox
            position={'absolute'}
            left={'0'}
            top={'0'}
            height={'64px'}
            width={'45px'}
            zIndex={-1}
            marginLeft={'2px'}
            marginTop={'5px'}
          >
            <Image
              loading="eager"
              src={SalesWidgetPlaceholder}
              name={`${playerName} + ' card'`}
              height={'100%'}
              width={'100%'}
            />
          </FlexBox>
          <FlexBox
            zIndex={2}
            height={'100%'}
            width={'100%'}
            backgroundImage={`url('${imageSource}')`}
            backgroundPosition={'center'}
            backgroundRepeat={'no-repeat'}
            backgroundSize={'cover'}
          />
        </FlexBox>
      )}
      <FlexBox flexDirection={'column'}>
        <FlexBox alignItems={'center'}>
          <TextField
            fontFamily={theme.fonts.primary}
            letterSpacing={theme.letterSpacings.primary}
            fontSize={'12px'}
            p={0}
            lineHeight={'16px'}
            fontWeight={600}
            content={truncateString(20, playerName)}
          />
          <Image
            ml={'12px'}
            loading="eager"
            src={getCDNUrl(`/App/images/latest-sales/sale-${scarcity}.png`)}
            name={scarcity || ''}
            width={'54px'}
            height="100%"
          />
        </FlexBox>
        <TextField
          fontSize={'10px'}
          p={0}
          lineHeight="14px"
          fontWeight={'400'}
          mt={'6px'}
          primaryHighlightColor={theme.colors.secondaryBG}
          content={`**Traded** ${getActivityTimeFormat(saleDate)}`}
        />
        <FlexBox mt={'6px'}>
          <TextField
            backgroundColor={theme.colors.darkGunmetal}
            fontSize={'10px'}
            lineHeight="14px"
            fontWeight={'400'}
            p={'4px'}
            textAlign={'center'}
            alignSelf={'center'}
            color={theme.colors.whites[8]}
            borderTopLeftRadius={'2px'}
            borderBottomLeftRadius={'2px'}
          >
            For
          </TextField>
          <TextField
            backgroundColor={theme.colors.lightSecondary}
            fontSize={'10px'}
            lineHeight="14px"
            fontWeight={'500'}
            p={'4px'}
            borderTopRightRadius={'2px'}
            borderBottomRightRadius={'2px'}
            textAlign={'center'}
            alignSelf={'center'}
            color={theme.colors.white}
            content={`${formattedPrice}`}
          />
        </FlexBox>
      </FlexBox>
    </WidgetLayoutContainer>
  )
}

export default LatestCardSaleItems
