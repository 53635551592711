import { LatestSaleWidgetProps } from 'interfaces'
import { useEffect, useState } from 'react'
import { LatestSaleWrapper } from './LatestSaleStyle'
import LatestCardSaleItems from './LatestCardSaleItems'
import { getLatestSale } from 'api/User.api'
import { IApiException } from 'api/Api.exception'
import { emitCustomEvent } from 'components/CustomEvents'
import Marquee from 'react-fast-marquee'
import { FlexBox, trackEvent, useInView } from '@rario/shared-components'
import WidgetsShimmer from 'components/HomePage/WidgetsShimmer'

const LatestSalesListing = () => {
  const [salesData, setSalesData] = useState<LatestSaleWidgetProps[]>()
  const [play, setIsplay] = useState<boolean>()
  const POLL_INTERVAL = 120000
  const [element, setElement] = useState<HTMLElement | null | undefined>()
  const [inView] = useInView(element)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (inView) {
      trackEvent('Section Viewed', 'track', {
        category: 'homepage',
        sub_category: 'homepage-latest_sales_cards',
        view_type: 'constrained',
        type: 'cards',
        cards_count: 10,
      })
    }
  }, [inView])

  const isTouchDevice = typeof window !== 'undefined' && 'ontouchstart' in document.documentElement
  const getLatestSaleData = async () => {
    try {
      const { data } = await getLatestSale()
      setSalesData(data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      const error = e as IApiException
      const errorResponse = error.response ? error.response : { message: error.message }
      emitCustomEvent('errorCodes', errorResponse)
    }
  }

  useEffect(() => {
    getLatestSaleData()
    const interval = setInterval(() => {
      getLatestSaleData()
    }, POLL_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  if (loading) {
    return <WidgetsShimmer height="87px" />
  }

  return (
    <LatestSaleWrapper
      width={'100%'}
      position="relative"
      overflow="hidden"
      ml="10px"
      mt={'24px'}
      alignSelf={'center'}
      ref={setElement}
    >
      {salesData && (
        <FlexBox
          width={'100%'}
          {...{
            onTouchStart: () => {
              setIsplay(false)
            },
            onTouchEnd: () => {
              setIsplay(true)
            },
          }}
        >
          <Marquee speed={40} gradient={false} play={play} pauseOnHover={!isTouchDevice}>
            {salesData.slice(0, 10).map((val: LatestSaleWidgetProps, index: number) => {
              return <LatestCardSaleItems responseVal={val} key={index} />
            })}
          </Marquee>
        </FlexBox>
      )}
    </LatestSaleWrapper>
  )
}

export default LatestSalesListing
