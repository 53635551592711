import {
  Button,
  Carousel,
  FlexBox,
  Image,
  Loader,
  TextField,
  theme,
  trackEvent,
  Video,
} from '@rario/shared-components'
import RarioLogo from 'assets/svg/RarioLogo'
import RightArrowSmallIcon from 'assets/svg/RightArrowSmallIcon'
// import RightArrowSmallIcon from 'assets/svg/RightArrowSmallIcon'
import { useRouter } from 'next/router'
import { getCDNUrl } from 'utils/utils'
import { useState } from 'react'
import { CarousalInner, CarousalWrapper, InfoHeading } from './InfoScreen.styles'

const carousalData = [
  {
    heading: 'INVEST IN CRICKET FANDOM',
    description:
      'Collect official limited-edition Digital Player Cards of top cricketers to access online games and unlock real world experiences.',
    imageSrc: getCDNUrl('/App/images/info-screen/invest-slide-poster.png'),
    video: getCDNUrl('/App/videos/info-screen/invest-slide-v2.webm'),
  },
  {
    heading: 'PLAY TO WIN',
    description:
      'Create 3 member teams with your Digital Player Cards to enter free contests during the Indian T20 League and beyond! Up to $3.5 Million to be won.',
    imageSrc: getCDNUrl('/App/images/info-screen/play-slide-poster.png'),
    video: getCDNUrl('/App/videos/info-screen/play-slide-v1.webm'),
  },
  {
    heading: 'BUY & SELL',
    description:
      'Trade Digital Player Cards with other fans on the marketplace. Use your cricket knowledge to enhance your card collection and make even more money.',
    imageSrc: getCDNUrl('/App/images/info-screen/buy-sell-slide-poster.png'),
    video: getCDNUrl('/App/videos/info-screen/buy-sell-slide-v1.webm'),
  },
  {
    heading: 'UNLOCK EXPERIENCES',
    description:
      'Gain access to exclusive experiences like VIP match tickets, signed merchandise and even meeting your favourite stars from your most loved IND T20 Teams.',
    imageSrc: getCDNUrl('/App/images/info-screen/experience-slide-poster.png'),
    video: getCDNUrl('/App/videos/info-screen/experience-slide-v1.webm'),
  },
]

const InfoScreens: React.FunctionComponent = () => {
  const { push } = useRouter()
  const [isLoading] = useState(false)

  const trackSignUp = () => {
    trackEvent('Section Interacted', 'click', {
      sub_category: 'info-screen_sign_up',
      click_text: 'SIGNUP',
      click_type: 'button',
      click_action: 'redirect_to_sign_up_screen',
      category: 'sign_up',
    })
  }

  return (
    <>
      {isLoading ? (
        <Loader height="100vh" />
      ) : (
        <FlexBox
          width={'100%'}
          flexDirection={'column'}
          textAlign={'center'}
          alignItems="center"
          pb="80px"
          position={'relative'}
        >
          <FlexBox
            position={'absolute'}
            top={'-15px'}
            left={'0'}
            bottom={'0'}
            width={['100%', '400px']}
          >
            <Image
              src={getCDNUrl('/App/images/info-screen/info-screen-bg.png')}
              name={'app-onboarding-background'}
              height={'100%'}
              width={'100%'}
            />
          </FlexBox>
          <FlexBox px={15} width={'100%'} justifyContent="flex-start" pt={15} zIndex={'999999'}>
            <RarioLogo />
          </FlexBox>
          <CarousalWrapper>
            <Carousel
              slidesToScroll={1}
              dragFree={false}
              speed={8}
              autoPlay={true}
              arrows={false}
              showAnimatedSlider={carousalData?.length > 1}
              isInfoScreen
              sliderHighlightColor="#EE332A"
              loop={false}
              stopOnLastSnap
              totalSlides={carousalData?.length}
            >
              {carousalData?.map((slide, index) => (
                <CarousalInner
                  flexDirection={'column'}
                  key={index}
                  height="fit-content"
                  width={['auto', '400px']}
                >
                  <FlexBox width={['100%', '400px']} zIndex={'99999'}>
                    <Video
                      src={slide.video}
                      mediaType={'video/webm'}
                      controls={false}
                      muted={true}
                      playsinline={true}
                      poster={slide.imageSrc}
                    />
                  </FlexBox>
                  <FlexBox
                    pt={[40, null, null, 80]}
                    flexDirection={'column'}
                    className={`info__slide${index} info__slide`}
                  >
                    <InfoHeading
                      fontSize={'24px'}
                      fontWeight="600"
                      lineHeight={'34px'}
                      color={theme.colors.white}
                      textTransform="uppercase"
                      width={['100vw', '100%']}
                      fontFamily={theme.fonts.primary}
                      letterSpacing={theme.letterSpacings.primary}
                      pb="0"
                    >
                      {slide.heading}
                    </InfoHeading>

                    <TextField
                      fontSize={'12px'}
                      fontWeight="400"
                      lineHeight={'16px'}
                      color={theme.colors.whites[7]}
                      mt="15px"
                      width={['100vw', '100%']}
                      px="20px"
                      pb="0"
                    >
                      {slide.description}
                    </TextField>
                  </FlexBox>
                </CarousalInner>
              ))}
            </Carousel>
          </CarousalWrapper>

          <FlexBox mt="24px" mx="auto">
            <Button
              variant={'dynamic'}
              name={'LOGIN'}
              onClick={() => {
                trackSignUp()
                push(`/login`)
              }}
              fontSize={'16px'}
              rightIcon={<RightArrowSmallIcon />}
            />
          </FlexBox>
        </FlexBox>
      )}
    </>
  )
}

export default InfoScreens
