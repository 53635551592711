import { FlexBox } from '@rario/shared-components'
import { Line, Card, ShimmerElement } from './WidgetsShimmer.styles'

const WidgetsShimmer = ({ height = '200px' }) => {
  return (
    <FlexBox
      flexDirection={'column'}
      width={'90%'}
      mx="auto"
      mt="24px"
      height={height}
      overflow={'hidden'}
    >
      <FlexBox>
        <FlexBox
          height={'22px'}
          position="absolute"
          left={0}
          width="6px"
          borderRadius={'0px 10px 10px 0px'}
          background={'#0E131A'}
        />
        <Line position="relative">
          <ShimmerElement />
        </Line>
      </FlexBox>

      <FlexBox mt="20px" width="150%">
        <Card position={'relative'}>
          <ShimmerElement />
        </Card>
        <Card position={'relative'}>
          <ShimmerElement />
        </Card>
      </FlexBox>
    </FlexBox>
  )
}

export default WidgetsShimmer
